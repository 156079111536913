<template>
  <div class="competitive-advantages-section">
    <section-title chTitle="竞争优势" enTitle="Competitive Advantages"></section-title>
    <div style="padding-left: 12px;padding-right: 12px;padding-top: 10px;">
      <div class="introduce">
        2017年4月，夸克医药（中国）有限公司在成都高新区设立，专注于所选生物医药项目在中国本土的价值提升和商业化。
      </div>
      <div style="padding: 10px 0px;">
        <img src="../../image/about/img2.png" style="width: 100%;" />
      </div>
      <p style="color:#f36314;font-size: 17px;padding: 10px 0px;">夸克医药（中国）有限公司的优势</p>
      <p class="introduce">
        夸克医药（中国）有限公司将引入并建立世界级的基础设施和专家人才体系以保证推动其在新药、医疗器械、生物标靶和生物疫苗等方面项目的研发进程。
      </p>
      <div class="flex-column center" style="margin-top:35px ;">
        <img src="../../image/about/shoujiwangluo.png" class="intro-icon" />
        <div class="icon-title">收集网略</div>
      </div>
      <p class="introduce center icon-intro">
        拥有全球化的生物医药技术项目收集网络以及独特的科学及商业委员会，能够高效快速地发现、筛选、获取真正具有里程碑式突破的技术项目。 </p>

      <div class="flex-column center" style="margin-top:35px ;">
        <img src="../../image/about/quanqiuhezuo.png" class="intro-icon" />
        <div class="icon-title">全球战略合作</div>
      </div>
      <p class="introduce center icon-intro">
        已和加拿大药物研发中心，美国国家国家药物研发中心，英国MRCT中心，德国马克斯普朗克研究所，欧洲转化医学基础设施中心和澳大利亚医疗创新中心建立了战略合作关系。 </p>
       
       <div class="flex-column center" style="margin-top:35px ;">
         <img src="../../image/about/jingrongziben.png" class="intro-icon" />
         <div class="icon-title">金融资本</div>
       </div>
       <p class="introduce center icon-intro">
       已和中国知名的金融及产业资本建立起全面战略合作伙伴关系；纳入夸克医药（中国）有限公司系统的项目公司能够获得强大的金融支持。
       </p>
       
       <div class="flex-column center" style="margin-top:35px ;">
         <img src="../../image/about/duopingtai.png" class="intro-icon" />
         <div class="icon-title">多样化平台</div>
       </div>
       <p class="introduce center icon-intro">
       旗下拥有多样化的技术平台和不同商业化阶段的项目公司，保证整个体系最短时间内实现自主造血和长期的可持续发展。
       </p>



    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  export default {
    components: {
      SectionTitle
    }
  }
</script>

<style lang="scss">
  .competitive-advantages-section {

    .introduce {
      font-size: 13px;
      color: #525252;
      line-height: 20px;

      &.center {
        text-align: center;
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;

      &.center {
        align-items: center;
        justify-content: center;
      }
    }

    .icon-title {
      line-height: 52px;
      font-size: 16px;
      color: #23a7f3;
    }

    .icon-intro {
      padding-left: 30px;
      padding-right: 30px;
    }



    .intro-icon {
      width: 95px;
    }
  }
</style>
