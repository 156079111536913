<template>
  <div class="strategy-goals-section">
    <section-title chTitle="战略目标" enTitle="Strategy Goals"></section-title>
    <div style="padding-left: 12px;padding-right: 12px;">
      <div style="font-size: 17px;color:#23a7f3;line-height: 40px;">高效 突破 创新</div>
      <div style="font-size: 13px;color:#525252;line-height: 22px;min-height: 180px;">
        从全球生物医药项目识别体系中，遴选出具有真正突破性和创新性的技术项目。通过多样化资本运作，实现项目价值提升。架设生物医药创新公司和金融资本之间的桥梁。
        建立起先进的实验设施和科学家团队；在世界范围内设立区域中心。培养各类研发、产业化人才以及真正具备将生物医药项目推进和商业化的战略发展能力的领军人才。
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  export default {
    components: {
      SectionTitle
    }
  }
</script>

<style lang="scss">
  .strategy-goals-section {
    background-image: url(../../image/about/strategy-goals-bg.png);
    background-repeat: no-repeat;
    background-position: right 50px;
    background-size: 200px;
  }
</style>
