<template>
  <div class="team-section">
    <section-title chTitle="核心团队" enTitle="Team"></section-title>
    <div class="section-intro">
      夸克医药（中国）有限公司中国中心拥有一支稳定且锐意进取的骨干力量，他们即是公司的核心团队，同时也是公司的中高级管理人员，他们都是国内外知名大学毕业的高材生，其中本科及以上学历100%，博士硕士占50%，海外背景人才占65%。核心团队有多年的行业经验和合作经验。
    </div>
    <div style="padding: 45px 12px;">
      <img src="../../image/about/education.png" style="width: 100%;" />
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  export default {
    components: {
      SectionTitle
    }
  }
</script>

<style lang="scss">
  .team-section {
   padding-top: 54px;
  }
</style>
