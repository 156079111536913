<template>
  <div class="core-missions-section">
    <div class="core-mission-content">
      <div style="height: 20.5%;">
        <section-title chTitle="核心任务" enTitle="Core Missions"></section-title>
      </div>
      <div style="height: 54.67%;color:#fff;">
        <swiper class="swiper-wrap" style="height: 100%;"   :options="swiperOption">
          <swiper-slide :key="index" style="height: 100%;"  class="swipe-item" v-for="(item,index) in coreMissions">
            <div style="padding-top: 8%;padding-left: 12px;padding-right: 12px;font-size: 16px;">{{item.title}}</div>
            <div style="padding-top: 6%;padding-left: 12px;padding-right: 12px;line-height: 20px;font-size: 13px;">{{item.intro}}</div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="pagination-wrap">
        <div class="pagination core-mission-pagination"></div>
      </div>
    </div>

  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  export default {
    components: {
      SectionTitle,
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        swiperOption: {
          autoplay: {
            disableOnInteraction: false,
            delay: 30000,
          },
          pagination: {
            el: '.core-mission-pagination',
          },
        },
        coreMissions: [{
          title: '培养人才',
          intro:'培养各类研发、产业化人才以及真正具备将生物医药项目推进和商业化的战略发展能力的领军人才。'
        },
        {
          title: '全球影响力',
          intro:'通过不断成功的商业化项目和高速发展的创新公司，提升夸克医药（中国）有限公司在生物医药行业的地位和话语权以及全球影响力。'
        },
        {
          title: '价值提升',
          intro:'从全球生物医药项目识别体系中，遴选出具有真正突破性和创新性的技术项目。通过多样化资本运作，实现项目价值提升。架设生物医药创新公司和金融资本之间的桥梁。'
        },
        {
          title: '产业升级',
          intro:'中国中心的设立将为四川打造汇集各类专家，人才和创新生物医药公司的产业集群；在短时间内提升四川生物医药产业升级换代，实现以高科技产业为核心引领的经济转型。'
        }
        ]
      }
    }
  }
</script>

<style lang="scss">
  .core-missions-section {
    margin-top: 50px;
    position: relative;
    background-image: url(../../image/about/core-task-bg.png);
    padding-top: 100%;
    background-size: 100%;
    background-repeat: no-repeat;

    .core-mission-content {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
</style>
