<template>
  <div class="development-history-item" :style="{paddingTop:top +'px'}">
    <div class="history-line"></div>
    <div class="circle-wrap"><div class="inner-circle"></div></div>
    <div class="date-item">{{item.year}}<div style="margin-top: 6px;">{{item.date}}</div></div>
    <div class="history-detail">
      <div class="history-detail-title">{{item.desc}}</div>
      <img class="history-detail-img"  :src="item.img"/>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      top:{
        type:Number,
        default:30
      },
      item:Object
    },
    data() {
      return {

      }
    }
  }
</script>

<style lang="scss">
  .development-history-item{
    position: relative;
    display: flex;
    padding-top: 20px;
    padding-bottom: 1px;
    box-sizing: border-box;
    
    .date-item{
      padding-left: 11px;
      width: 86px;
      color:#535353;
      font-size: 13px;
      box-sizing: border-box;
      
    }
    .history-detail{
      flex: 1;
      
      display: flex;
      flex-direction: column;
      
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      .history-detail-title{
        font-size: 13px;
        line-height: 20px;
        color:#999999;
      }
      .history-detail-img{
        margin-top: 14px;
        width: 140px;
      }
      
    }
    
    .history-line{
      position: absolute;
      width: 1px;
      background-color: #b6b6b6;
      left:86px;
      top: 0px;
      bottom: 0px;
    }
    
    .circle-wrap{
      position: absolute;
      left:78px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      border:1px solid #f36314;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      
      .inner-circle{
        width: 8px;
        height: 8px;
        background-color:#f36314 ;
        border-radius: 8px;
      }
    }
    
    
    
  }
</style>
