<template>
  <div class="development-history-section">
    <section-title chTitle="发展历程" enTitle="Development History"></section-title>
    <div class="history-wrap">
      <history-item :key="index" :item="item" v-for="(item,index) in historyList"></history-item>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  import HistoryItem from '@/components/history-item.vue'
  export default {
    components: {
      SectionTitle,
      HistoryItem
    },
    data() {
      return {
        historyList: [
          {
            "img": require('@/image/about/history/2018-4-27.png'),
            "time": "2018年4月27日",
            "year":"2018年",
            "date":"4月27日",
            "desc": "成功举办“首届天府国际生物医药商业化高端对话”。该会议标志夸克医药（中国）有限公司在中国的项目商业化运作全面展开。"
          },
          {
            "img": require('@/image/about/history/2017-9-14.png'),
            "time": "2017年9月14日",
            "year":"2017年",
            "date":"9月14日",
            "desc": "夸克医药（中国）有限公司与广发证券共同发起规模100亿元人民币的四川生物医药产业基金，该基金专门配套夸克医药（中国）有限公司落地中国项目的商业化。"
          },
          {
            "img": require('@/image/about/history/2016-11-2.png'),
            "time": "2016年11月2日",
            "year":"2016年",
            "date":"11月2日",
            "desc": "夸克医药（中国）有限公司同四川省投资促进局签订战略合作协议，夸克医药（中国）有限公司中国中心项目正式落户成都。"
          },
          {
            "img": require('@/image/about/history/2016-11-1.png'),
            "time": "2016年11月1日",
            "year":"2016年",
            "date":"11月1日",
            "desc": "夸克医药（中国）有限公司与成都高新区签订投资合作协议。"
          }
        ]
      }
    }
  }
</script>

<style lang="scss">
  .development-history-section {
    padding-top: 64px;

    .history-wrap {
      margin-top: 22px;
      
      background-image: url(../../image/about/earth.png);
      background-size: 100%;
      background-position-y: center;
      background-repeat: no-repeat;
    }
  }
</style>
