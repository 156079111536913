<template>
  <div class="about-page">
    <div class="banner">
      <img src="@/image/about/banner.png" />
    </div>
    <div class="">
      <!--导航条-->
      <navigation-bar pageName="关于我们"></navigation-bar>
      <!--战略目标-->
      <strategy-goals></strategy-goals>
      <!--竞争优势-->
      <competitive-advantages></competitive-advantages>
      <!--核心任务-->
      <core-missions></core-missions>
      <!--发展历程-->
      <development-history></development-history>
      <!--核心团队-->
      <team></team>
      <!--办公环境-->
      <office-environment></office-environment>
    </div>
  </div>
</template>

<script>
  import NavigationBar from '@/components/navigation-bar.vue'
  import StrategyGoals from './StrategyGoals.vue'
  import CompetitiveAdvantages from './CompetitiveAdvantages.vue'
  import CoreMissions from './CoreMissions.vue'
  import DevelopmentHistory from './DevelopmentHistory.vue'
  import Team from './Team.vue'
  import OfficeEnvironment from './OfficeEnvironment.vue'
  export default {
    components: {
      NavigationBar,
      StrategyGoals,
      CompetitiveAdvantages,
      CoreMissions,
      DevelopmentHistory,
      Team,
      OfficeEnvironment
    }
  }
</script>

<style lang="scss">
 .about-page{
   .banner{
     img{
       width: 100%;
     }
   }
   
   .section-intro{
     padding: 10px 12px;
     font-size: 13px;
     color:#535353;
     line-height: 20px;
   }
   
   
 }
 .content-body{
  
 }

</style>
