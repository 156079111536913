<template>
  <div class="office-environment-section">
    <section-title chTitle="办公环境" enTitle="Office Environment"></section-title>
   <div class="section-intro">
     夸克医药（中国）有限公司中国中心坐落于美丽的天府之国-成都。公司拥有舒适、宽敞的办公环境和一流的办公设备。
   </div>
   
   <div class="office-environment-wrap">
     <div class="office-environment-body">
       <swiper class="swiper-wrap"  :options="swiperOption">
         <swiper-slide :key="index" v-for="(item,index) in officeImageList">
         <img :src="item" style="height: calc(100vw * 0.544);" />
         </swiper-slide>
       </swiper>
       <div class="pagination-wrap office-pagination-wrap">
         <div class="pagination office-pagination"></div>
       </div>
     </div>
   </div>
   
  </div>
</template>

<script>
  import SectionTitle from '@/components/section-title.vue'
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  export default {
    components: {
      SectionTitle,
      Swiper,
      SwiperSlide
    },
    data(){
      return {
        officeImageList:[
          "http://www.gdcccn.com/upload/image/office/1.png",
          "http://www.gdcccn.com/upload/image/office/2.png",
          "http://www.gdcccn.com/upload/image/office/3.png",
          "http://www.gdcccn.com/upload/image/office/4.png",
          "http://www.gdcccn.com/upload/image/office/5.png",
          "http://www.gdcccn.com/upload/image/office/6.png",
        ],
        swiperOption: {
          autoplay: {
            disableOnInteraction: false,
            delay: 30000,
          },
          pagination: {
            el: '.office-pagination',
          },
        }
      }
    }
  }
</script>

<style lang="scss">
  .office-environment-section {
  
    .office-environment-wrap{
      position: relative;
      padding-top: 92.67%;
      background-size: 100%;
      background-image: url(../../image/about/officebg.png);
        
      .office-environment-body{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0px 12px;
        padding-top: 20%;
      }
      .office-pagination-wrap{
        padding-top: 24px;
      }
    }
  }
</style>
